import { HomeQuery, Maybe } from "lib/graphql";
import { Star } from "phosphor-react";
import { Image, ResponsiveImageType } from "react-datocms";

import { Swiper, SwiperSlide } from "swiper/react";

function Testimonial({
  photo,
  quote,
  name,
  rating,
}: {
  photo: Maybe<ResponsiveImageType>;
  quote: string;
  name: string;
  rating: number;
}) {
  return (
    <div className="bg-white rounded-md relative w-full h-full">
      <div className="h-full flex flex-col pb-12 pt-16 px-8 justify-center">
        <div>
          <p className="font-serif text-3xl pb-3 text-center">
            <q>{quote}</q>
          </p>
          <p className="text-center font-serif text-3xl pb-6">
            <span>—{name}</span>
          </p>
        </div>
        <div className="flex items-center justify-center" aria-hidden>
          <span className="sr-only">{rating} stars</span>
          {Array.from({ length: rating }).map((_, i) => (
            <Star
              weight="fill"
              size={24}
              className="fill-brand-yellow-bright"
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

interface Props {
  page: HomeQuery;
}

export function Testimonials(props: Props) {
  return (
    <div className="bg-brand-gray py-16 lg:py-24 overflow-hidden">
      <div className="container">
        <div className="text-center">
          <div className="space-y-3">
            <h3 className="text-4xl lg:text-5xl font-serif">
              What our customers are saying.
            </h3>
          </div>
        </div>
      </div>
      <div className="mt-16 lg:mt-24">
        <div className="container">
          <Swiper
            aria-label="Testimonials"
            autoHeight
            spaceBetween={24}
            slidesPerView="auto"
            loop
            className="!overflow-visible stretch-carousel"
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
            }}
          >
            {props.page.homePage?.testimonials.map((testimonial) => {
              return (
                <SwiperSlide key={testimonial.id}>
                  <Testimonial
                    photo={testimonial.photo.responsiveImage}
                    quote={testimonial.quote}
                    name={testimonial.name}
                    rating={testimonial.rating}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
