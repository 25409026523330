import { StoreButtons } from "components/app-store-button";
import { Logo } from "components/logo";
import { HomeQuery } from "lib/graphql";
import { Image } from "react-datocms";
import { Marquee } from "./marquee";

export function Hero({ page }: { page: HomeQuery }) {
  return (
    <>
      <div
        className="flex items-center justify-center bg-brand-gray relative"
        style={{ height: "calc(100vh - var(--header-height, 3.5rem))" }}
      >
        <div className="hero-mobile absolute inset-0 w-full h-full pointer-events-none sm:hidden">
          <Image
            objectFit="cover"
            className="absolute inset-0 w-full h-full"
            pictureClassName="w-full h-full"
            objectPosition={"bottom center"}
            // @ts-ignore
            data={page.homePage?.heroImageMobile.responsiveImage}
          />
        </div>
        <div className="absolute inset-0 w-full h-full pointer-events-none hidden sm:block lg:hidden">
          <Image
            objectFit="cover"
            className="absolute inset-0 w-full h-full"
            pictureClassName="w-full h-full"
            // @ts-ignore
            data={page.homePage?.heroImageMid.responsiveImage}
          />
        </div>
        <div className="absolute inset-0 w-full h-full pointer-events-none hidden lg:block">
          <Image
            objectFit="cover"
            className="absolute inset-0 w-full h-full hero-max-width"
            pictureClassName="w-full h-full"
            // @ts-ignore
            data={page.homePage?.heroImageDesktop.responsiveImage}
          />
        </div>
        <div className="container relative pb-40 md:pb-0">
          <div className="space-y-4 lg:space-y-8 flex flex-col items-center">
            <div className="hidden lg:block">
              <div className="text-brand-blue w-28">
                <Logo />
              </div>
            </div>
            <h1 className="font-serif text-3xl lg:text-5xl text-center lg:max-w-lg">
              A trusted partner for<br></br>every fertility journey.
            </h1>
            <p className="text-center text-xl lg:text-2xl max-w-sm">
              Find and manage your fertility treatment, all in one app.
            </p>
            <div>
              <StoreButtons />
            </div>
          </div>
        </div>
      </div>
      <Marquee />
    </>
  );
}
