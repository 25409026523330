import { sdk } from "lib/dato";
import type { InferGetStaticPropsType } from "next";
import "swiper/css";

import { Callout } from "components/callout";
import { Advisors } from "components/home/advisors";
import { CTAs } from "components/home/ctas";
import { Hero } from "components/home/hero";
import { Screenshots } from "components/home/screenshots";
import { Testimonials } from "components/home/testimonials";
import { Layout } from "components/layout";
import { LearnMore } from "components/learn-more";

export type HomePageProps = InferGetStaticPropsType<typeof getStaticProps>;

const Home = ({ articles, site, page }: HomePageProps) => {
  return (
    <Layout navbarVariant="dynamic" site={site} page={page.homePage?.seo}>
      <section id="hero">
        <Hero page={page} />
      </section>
      <section id="get-started">
        <CTAs />
      </section>
      <section id="screenshots">
        <Screenshots />
      </section>
      <section id="testimonials">
        <Testimonials page={page} />
      </section>
      {/* <section id="advisors">
        <Advisors />
      </section> */}
      <section id="learn-more">
        <LearnMore articles={articles.allArticles} />
      </section>
      <section id="callout">
        <Callout />
      </section>
    </Layout>
  );
};

export default Home;

export const getStaticProps = async () => {
  return {
    props: {
      site: await sdk.SiteSettings(),
      page: await sdk.Home(),
      articles: await sdk.AllArticles(),
    },
    revalidate: 10,
  };
};
