import { AllArticlesQuery } from "lib/graphql";
import { ArticlePreview } from "./article-preview";
import { Swiper, SwiperSlide } from "swiper/react";

export function LearnMore({
  articles,
}: {
  articles: AllArticlesQuery["allArticles"];
}) {
  return (
    <div className="bg-brand-gray py-16 lg:py-24 overflow-hidden">
      <div className="container">
        <div className="text-center">
          <h3 className="text-4xl lg:text-5xl font-serif">Learn more</h3>
        </div>
      </div>
      <div className="mt-16 lg:mt-24">
        <div className="container">
          <Swiper
            aria-label="Articles"
            autoHeight
            spaceBetween={24}
            slidesPerView="auto"
            loop
            className="!overflow-visible stretch-carousel"
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
            }}
          >
            {articles.map((article) => {
              return (
                <SwiperSlide key={article.slug}>
                  <ArticlePreview article={article} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
