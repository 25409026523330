import Image from "next/image";
import { ScreenshotBlock } from "./screenshot-block";
import YourTreatment from "public/screenshots/your-treatment.webp";
import Demystify from "public/screenshots/demystify.webp";
import TrackEverything from "public/screenshots/track-everything.webp";
import Questions from "public/screenshots/questions.webp";

export function Screenshots() {
  return (
    <ul className="py-8 lg:py-16 space-y-16">
      <li className="group">
        <ScreenshotBlock
          className="bg-brand-gray"
          heading="Understand your treatment at every stage."
          content="Find the right fertility treatment, personalized just for you."
          visual={
            <div>
              <Image alt="TK TK" src={YourTreatment} />
            </div>
          }
        />
      </li>
      <li className="group">
        <ScreenshotBlock
          heading="Demystify your medications."
          content="Organize your medications easily, with clear medical information and how-tos. You got this."
          visual={
            <div className="lg:pr-8">
              <Image alt="TK TK" src={Demystify} />
            </div>
          }
        />
      </li>
      <li className="group">
        <ScreenshotBlock
          className="bg-brand-pink-dusty"
          heading="Track everything in one place."
          content="Track your treatment, including appointments, dosage time reminders, ultrasound dates, and more."
          visual={
            <div>
              <Image alt="TK TK" src={TrackEverything} />
            </div>
          }
        />
      </li>
      <li className="group">
        <ScreenshotBlock
          heading="Questions? Talk to us. No robots, we promise."
          content="Chat with us or get in touch via email. We want to know how we can help."
          visual={
            <div className="lg:pr-4">
              <Image alt="TK TK" src={Questions} />
            </div>
          }
        />
      </li>
    </ul>
  );
}
