interface Props {
  heading: React.ReactNode;
  className?: string;
  content: React.ReactNode;
  visual?: React.ReactNode;
}

export function ScreenshotBlock({
  heading,
  content,
  className = "",
  visual,
}: Props) {
  return (
    <div className="container group">
      <div
        className={`rounded-[3rem] lg:rounded-[7.5rem] ${className} lg:site-grid`}
      >
        <div className="lg:row-start-1 col-span-5 group-even:col-start-2 group-odd:col-start-7 flex lg:items-center justify-center px-8 pt-8 lg:px-0 lg:py-8">
          {visual}
        </div>
        <div className="lg:row-start-1 col-span-5 group-odd:col-start-2 group-even:col-start-7 flex items-center px-8 lg:p-0">
          <div className="space-y-6 py-12 lg:py-24 text-center lg:text-left flex-1">
            <h3 className="font-serif text-4xl lg:text-5xl lg:max-w-sm">
              {heading}
            </h3>
            <p className="text-xl lg:text-2xl lg:max-w-lg">{content}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
