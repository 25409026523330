import { Pill, Sun, CalendarCheck } from "phosphor-react";

const MarqueeItem = ({ icon, content }: { icon: any; content: string }) => {
  let IconEl = icon;
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 flex items-center relative">
        <IconEl size={32} aria-hidden="true" />
      </div>
      <span className="ml-3 text-lg flex-shrink-0">{content}</span>
    </div>
  );
};

let items = (
  <>
    <li>
      <MarqueeItem icon={CalendarCheck} content="Track your progress" />
    </li>
    <li>
      <MarqueeItem icon={Sun} content="Understand your treatment" />
    </li>
    <li>
      <MarqueeItem icon={Pill} content="Demystify your medications" />
    </li>
  </>
);

export function Marquee() {
  return (
    <div className="h-14 bg-[#E1E9FA] marquee marquee--hover-pause">
      <ul className="marquee__content will-change-transform">
        {/* Doubling up here reduces the perceived visual space between groups... */}
        {items}
        {items}
      </ul>
      <ul className="marquee__content will-change-transform" aria-hidden="true">
        {items}
        {items}
      </ul>
    </div>
  );
}
