import { HomePageProps } from "pages";
import { Image } from "react-datocms";
import Link from "next/link";

export function ArticlePreview({
  article,
}: {
  article: HomePageProps["articles"]["allArticles"][0];
}) {
  let articleLink = `/articles/${article.slug}`;

  return (
    <div className="rounded-3xl overflow-hidden h-full bg-white">
      <div className="h-56 relative">
        <Image
          layout="fill"
          objectFit="cover"
          // @ts-ignore
          data={article?.coverImage?.responsiveImage}
        />
      </div>
      <div className="bg-white p-6 lg:p-8 space-y-1">
        <p className="text-sm">
          {article.categories?.map((category, i) => (
            <span key={`${category}-${i}`}>
              {i === 0 ? null : ", "}
              {category?.name}
            </span>
          ))}
        </p>
        <h3 className="text-3xl font-serif">
          <Link href={articleLink}>
            <a className="hover:underline">{article.title}</a>
          </Link>
        </h3>
      </div>
    </div>
  );
}
