import { StoreButtons } from "./app-store-button";

export function Callout() {
  return (
    <div className="py-16 lg:py-24">
      <div className="container flex flex-col items-center text-center space-y-8">
        <h3 className="text-4xl lg:text-5xl font-serif max-w-sm">
          Get started with Berry today.
        </h3>
        <StoreButtons />
      </div>
    </div>
  );
}